import React from 'react'

class Footer extends React.Component {
    render() {
        return (
            <section id="footer">
                <ul className="icons">
                    <li><a target="_blank" href="https://github.com/react-theme/photon" className="icon fa-github alt"><span className="label">GitHub</span></a></li>
                    <li><a target="_blank" href="https://twitter.com/webappseed" className="icon fa-twitter alt"><span className="label">Twitter</span></a></li>
                    <li><a target="_blank" href="https://facebook.com/webappseed" className="icon fa-facebook alt"><span className="label">Facebook</span></a></li>
                    <li><a target="_blank" href="https://instagram.com/webappseed" className="icon fa-instagram alt"><span className="label">Instagram</span></a></li>
                </ul>
                <ul className="copyright">
                    <li>AppSeed. Design: <a href="https://html5up.net">HTML5 UP</a>. Built with <a href="https://appseed.us">FullStack Web App Generator</a></li>
                </ul>
            </section>
        )
    }
}

export default Footer
