import React from 'react'

class Header extends React.Component {
    render() {
        return (
            <section id="header">
                <div className="inner">
                    <span className="icon major fa-cloud"></span>
                    <h1>React theme <strong>Photon</strong></h1>

                    <p>Crafted by HTML5 Up, Coded by AppSeed<br />
                    <a target="_blank" rel="noopener noreferrer" href="https://developers.google.com/web/progressive-web-apps/">PWA</a> App, {' '}
                    <a target="_blank" rel="noopener noreferrer" href="https://developers.google.com/speed/pagespeed/insights/?url=https://react-themes-photon.appseed.us">Fast As Hell</a>, {' '}
                    FTP deploy script, {' '}
                    <a target="_blank" rel="noopener noreferrer" href="https://github.com/react-theme/photon">Github Sources</a> {' '}
                    </p>

                    <ul className="actions">
                        <li><a href="#one" className="button scrolly">Features</a></li>
                    </ul>
                </div>
            </section>
        )
    }
}

export default Header
